import { forwardRef, ReactElement, Ref, TextareaHTMLAttributes } from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & { className?: string }

export const TextArea = forwardRef(function TextArea(props: Props, ref: Ref<HTMLTextAreaElement>): ReactElement {
    return <textarea {...props} className={classnames(css.textarea, props.className)} />
})
