import { ReactElement } from 'react'
import { useLingui } from '@lingui/react'

import { Currency as CurrencyE } from '../../../api/types'

export type Price = {
    amount: number
    currency: CurrencyE
}

type Props = {
    value: Price
}

export function Currency(props: Props): ReactElement {
    const {
        value: { amount, currency },
    } = props

    const { i18n } = useLingui()

    return (
        <>
            {i18n.number(amount, {
                style: 'currency',
                currency,
            })}
        </>
    )
}
