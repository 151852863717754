import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { NSFWSmallImageProps } from './types'
import { THUMBNAIL } from './constants'

import css from './styles.css'
import { login } from '../../login'

export function NSWFImagePlaceholderLarge(): ReactElement {
    return (
        <div className={css.nsfwLarge}>
            <p className={css.nswfLargeHeadline}>
                <Trans>Image hidden due to content</Trans>
            </p>
            <button className={css.seeWhyButton} onClick={() => login()}>
                <Trans>Log in to view image</Trans>
            </button>
        </div>
    )
}

export function NSFWImagePlaceholderSmall(props: NSFWSmallImageProps): ReactElement {
    const { showButton, variant } = props

    function smallVariantFormat() {
        if (variant === THUMBNAIL) {
            return css.nsfwSmallThumbnail
        }

        if (!showButton) {
            return css.nsfwSmallNoButton
        }

        return css.nsfwSmall
    }

    const variantSize = smallVariantFormat()

    return (
        <div className={variantSize}>
            <p className={css.nsfwSmallHeadline}>
                <Trans>Image hidden</Trans>
            </p>
            {showButton && (
                <p className={css.seeWhyButtonSmall}>
                    <Trans>Learn why</Trans>
                </p>
            )}
        </div>
    )
}
