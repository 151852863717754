import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'

import { ArtistRolloverFragment } from '../../api/types'
import { WithProps } from '../../lib/add-state'
import { Tooltip } from '../../lib/components/tooltip'
import { Link } from '../../lib/components/link'
import { isVarious } from '../../lib/is-various'

import { Props, State, Action } from '.'

import css from './styles.css'

type UIProps = WithProps<Props, State, Action>

export function ArtistLinkUI(props: UIProps): ReactElement | null {
    const { artist, displayName, nameVariation, open, info, dispatch, tabIndex } = props

    if (!artist && !nameVariation) {
        return null
    }

    const url = artist && 'siteUrl' in artist ? artist.siteUrl : undefined
    const hasTooltip = !isVarious(artist)

    function onMouseEnter(): void {
        if (window.getSelection()?.type === 'Range') {
            return
        }
        dispatch({ type: 'hover' })
    }

    function onMouseLeave(): void {
        dispatch({ type: 'leave' })
    }

    const name = nameVariation ? nameVariation : displayName || artist?.name
    const link = url ? (
        <Link tabIndex={tabIndex} href={url} title={hasTooltip ? undefined : artist?.name} className={css.link}>
            {name}
        </Link>
    ) : (
        <span title={hasTooltip ? undefined : artist?.name}>{name}</span>
    )

    return (
        <span className={css.link} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
            {link}
            {nameVariation && '*'}
            {hasTooltip && <ArtistTooltip artist={info} dispatch={dispatch} open={open} url={url} />}
        </span>
    )
}

type TooltipProps = {
    artist?: ArtistRolloverFragment | { name: string }
    url: string | undefined
    dispatch: UIProps['dispatch']
    open: boolean
}

function ArtistTooltip(props: TooltipProps): ReactElement | null {
    const { dispatch, artist, url, open } = props

    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    if (!artist || !('discogsId' in artist) || !artist.profile) {
        return null
    }

    const { name, realName, discogsId, profile } = artist

    function onClose(): void {
        dispatch({ type: 'close' })
    }

    const max = 650
    const overflowing = profile?.html.length > max
    const p = `${profile.html.substring(0, max)}${overflowing ? '…' : ''}`

    return (
        <Tooltip open={open} title={name} id={`artist-${discogsId}`} onClose={onClose} position='right'>
            <div className={css.inner}>
                <Link href={url} className={css.nav}>
                    {realName && (
                        <>
                            <h4>
                                <Trans>Real Name</Trans>
                            </h4>

                            {realName}
                        </>
                    )}
                    {profile?.html && (
                        <>
                            <h4>
                                <Trans>Profile</Trans>
                            </h4>

                            <div dangerouslySetInnerHTML={{ __html: p }} />
                        </>
                    )}
                    <div className={css.more}>
                        <Trans>Read More</Trans>
                    </div>
                </Link>
            </div>
        </Tooltip>
    )
}
