import { ReactElement, ReactNode, useRef, useEffect } from 'react'
import classnames from 'classnames'

import { Close } from '../icon'

import css from './styles.css'

const positions = {
    bottom: css.bottom,
    bottomEnd: css.bottom_end,
    right: css.right,
    top: css.top,
    topEnd: css.top_end,
    left: css.left,
}

type Props = {
    open?: boolean
    onClose: () => void
    title?: ReactNode
    children: ReactNode
    position: keyof typeof positions
    id: string
    role?: string
    ariaLabel?: string
    tabIndex?: number
    ariaHidden?: boolean
    keyHandler?: (e: { key: string }) => void
}

export function Tooltip(props: Props): ReactElement | null {
    const {
        children,
        title,
        onClose,
        position,
        id,
        open = false,
        role = 'tooltip',
        ariaLabel = '',
        tabIndex = -1,
        ariaHidden = 'false',
        keyHandler,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    useEffect(function () {
        ref.current?.focus()
    })

    if (!open) {
        return null
    }

    return (
        <div
            className={classnames(css.tooltip, positions[position])}
            role={role}
            aria-label={ariaLabel}
            id={id}
            onKeyDown={keyHandler ? (e) => keyHandler(e) : undefined}
            tabIndex={tabIndex}
            ref={ref}
            onBlur={(e) => !e.currentTarget.contains(e.relatedTarget) && onClose()}
        >
            {title && <h3 aria-hidden={ariaHidden}>{title}</h3>}
            <button onClick={onClose} className={css.close} role='menuitem' aria-label='close'>
                <Close />
            </button>
            <div className={css.content}>{children}</div>
        </div>
    )
}

type ContainerProps = {
    children: ReactNode
}

export function Container(props: ContainerProps): ReactElement {
    const { children } = props
    return <div className={css.container}>{children}</div>
}
