import { ReactElement } from 'react'

import { PrimaryArtist } from '../../api/types'
import { collateArtists } from '../../lib/collate-artists'
import { ArtistLink } from '../artist-link'

type LinksProps = {
    artists: PrimaryArtist[]
    disableArtistLinkHover?: boolean
}

export function ReleaseArtistLink(props: LinksProps): ReactElement {
    const { artists, disableArtistLinkHover = false } = props

    return (
        <>
            {collateArtists(artists, (artist: PrimaryArtist): ReactElement | null =>
                artist.artist ? (
                    <ArtistLink
                        key={'discogsId' in artist.artist ? artist.artist.discogsId : artist.artist.name}
                        artist={artist.artist}
                        nameVariation={artist.nameVariation}
                        displayName={artist.displayName}
                        disableArtistLinkHover={disableArtistLinkHover}
                    />
                ) : null,
            )}
        </>
    )
}
