import { ReactElement } from 'react'
import { Link } from 'react-head'

export function canonicalLink(locale: string, siteUrl: string): ReactElement {
    if (locale === 'en' || !locale) {
        return <Link data-rh='' rel='canonical' href={`https://www.discogs.com${siteUrl}`} />
    }

    return (
        <>
            <Link data-rh='' rel='canonical' href={`https://www.discogs.com/${locale}${siteUrl}`} />
            <Link data-rh='' rel='alternate' href={`https://www.discogs.com/${siteUrl}`} />
        </>
    )
}
